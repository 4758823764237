// export const Constant = {
//   ENV: "local",
//   API_URL: "http://192.168.1.10:3000/",
// };

// export const Constant = {
//   ENV: "dev",
//   API_URL: "https://callee-backend-dev-66wus.ondigitalocean.app/",
// };

export const Constant = {
  ENV: "prod",
  API_URL: "https://api.callee.app/",
};
